import {tns} from "tiny-slider";

window.tns = tns;

// Media queries breakpoints
window.screen_xs = 480;
window.screen_xs_min = screen_xs;
window.screen_phone = screen_xs_min;
window.screen_sm = 768;
window.screen_sm_min = screen_sm;
window.screen_tablet = screen_sm_min;
window.screen_md = 992;
window.screen_md_min = screen_md;
window.screen_desktop = screen_md_min;
window.screen_lg = 1200;
window.screen_lg_min = screen_lg;
window.screen_lg_desktop = screen_lg_min;
window.screen_xs_max = (screen_sm_min - 1);
window.screen_sm_max = (screen_md_min - 1);
window.screen_md_max = (screen_lg_min - 1);

window.resizeTimer = null;
window.collectionsSliderobj = [];
window.singleInstaSliderObj = [];

function get_vw() {
    return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
}

function get_vh() {
    return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
}

window.get_vh = get_vh;
window.get_vw = get_vw;

window.update_cart_info = function (){
    ajaxnav('', '#cart-info-wrp', 'content_types/cart/cart_info', false);
}

window.toLatin = function (str) {
    let from = [
        '¹', '²', '³', '°', 'æ', 'ǽ', 'À', 'Á', 'Â', 'Ã', 'Å', 'Ǻ', 'Ă', 'Ǎ', 'Æ', 'Ǽ', 'à', 'á', 'â', 'ã', 'å', 'ǻ', 'ă', 'ǎ', 'ª', '@', 'Ĉ', 'Ċ', 'ĉ', 'ċ', '©', 'Ð', 'Đ', 'ð', 'đ', 'È', 'É',
        'Ê', 'Ë', 'Ĕ', 'Ė', 'è', 'é', 'ê', 'ë', 'ĕ', 'ė', 'ƒ', 'Ĝ', 'Ġ', 'ĝ', 'ġ', 'Ĥ', 'Ħ', 'ĥ', 'ħ', 'Ì', 'Í', 'Î', 'Ï', 'Ĩ', 'Ĭ', 'Ǐ', 'Į', 'Ĳ', 'ì', 'í', 'î', 'ï', 'ĩ', 'ĭ', 'ǐ', 'į',
        'ĳ', 'Ĵ', 'ĵ', 'Ĺ', 'Ľ', 'Ŀ', 'ĺ', 'ľ', 'ŀ', 'Ñ', 'ñ', 'ŉ', 'Ò', 'Ô', 'Õ', 'Ō', 'Ŏ', 'Ǒ', 'Ő', 'Ơ', 'Ø', 'Ǿ', 'Œ', 'ò', 'ô', 'õ', 'ō', 'ŏ', 'ǒ', 'ő', 'ơ', 'ø', 'ǿ', 'º', 'œ', 'Ŕ', 'Ŗ', 'ŕ', 'ŗ', 'Ŝ', 'Ș', 'ŝ', 'ș',
        'ſ', 'Ţ', 'Ț', 'Ŧ', 'Þ', 'ţ', 'ț', 'ŧ', 'þ', 'Ù', 'Ú', 'Û', 'Ũ', 'Ŭ', 'Ű', 'Ų', 'Ư', 'Ǔ', 'Ǖ', 'Ǘ', 'Ǚ', 'Ǜ', 'ù', 'ú', 'û', 'ũ', 'ŭ', 'ű', 'ų', 'ư', 'ǔ', 'ǖ', 'ǘ', 'ǚ', 'ǜ', 'Ŵ', 'ŵ', 'Ý', 'Ÿ', 'Ŷ', 'ý', 'ÿ', 'ŷ',
        'Ъ', 'Ь', 'А', 'Б', 'Ц', 'Ч', 'Д', 'Е', 'Ё', 'Э', 'Ф', 'Г', 'Х', 'И', 'Й', 'Я', 'Ю', 'К', 'Л', 'М', 'Н', 'О', 'П', 'Р', 'С', 'Ш', 'Щ', 'Т', 'У', 'В', 'Ы', 'З', 'Ж', 'ъ', 'ь', 'а', 'б', 'ц', 'ч', 'д', 'е', 'ё', 'э',
        'ф', 'г', 'х', 'и', 'й', 'я', 'ю', 'к', 'л', 'м', 'н', 'о', 'п', 'р', 'с', 'ш', 'щ', 'т', 'у', 'в', 'ы', 'з', 'ж', 'Ä', 'Ö', 'Ü', 'ß', 'ä', 'ö', 'ü', 'Ç', 'Ğ', 'İ', 'Ş', 'ç', 'ğ', 'ı', 'ş',
        'Ā', 'Ē', 'Ģ', 'Ī', 'Ķ', 'Ļ', 'Ņ', 'Ū', 'ā', 'ē', 'ģ', 'ī', 'ķ', 'ļ', 'ņ', 'ū', 'Ґ', 'І', 'Ї', 'Є', 'ґ', 'і', 'ї', 'є', 'Č', 'Ď', 'Ě', 'Ň', 'Ř', 'Š', 'Ť', 'Ů', 'Ž', 'č', 'ď', 'ě', 'ň', 'ř', 'š', 'ť', 'ů', 'ž',
        'Ą', 'Ć', 'Ę', 'Ł', 'Ń', 'Ó', 'Ś', 'Ź', 'Ż', 'ą', 'ć', 'ę', 'ł', 'ń', 'ó', 'ś', 'ź', 'ż',
    ];

    let to = [
        1, 2, 3, 0, 'ae', 'ae', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'AE', 'AE', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'at', 'C', 'C', 'c', 'c', 'c', 'Dj', 'D', 'dj', 'd', 'E', 'E',
        'E', 'E', 'E', 'E', 'e', 'e', 'e', 'e', 'e', 'e', 'f', 'G', 'G', 'g', 'g', 'H', 'H', 'h', 'h', 'I', 'I', 'I', 'I', 'I', 'I', 'I', 'I', 'IJ', 'i', 'i', 'i', 'i', 'i', 'i', 'i', 'i',
        'ij', 'J', 'j', 'L', 'L', 'L', 'l', 'l', 'l', 'N', 'n', 'n', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'OE', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'oe', 'R', 'R', 'r', 'r', 'S', 'S', 's', 's',
        's', 'T', 'T', 'T', 'TH', 't', 't', 't', 'th', 'U', 'U', 'U', 'U', 'U', 'U', 'U', 'U', 'U', 'U', 'U', 'U', 'U', 'u', 'u', 'u', 'u', 'u', 'u', 'u', 'u', 'u', 'u', 'u', 'u', 'u', 'W', 'w', 'Y', 'Y', 'Y', 'y', 'y', 'y',
        '', '', 'A', 'B', 'C', 'Ch', 'D', 'E', 'E', 'E', 'F', 'G', 'H', 'I', 'J', 'Ja', 'Ju', 'K', 'L', 'M', 'N', 'O', 'P', 'R', 'S', 'Sh', 'Shch', 'T', 'U', 'V', 'Y', 'Z', 'Zh', '', '', 'a', 'b', 'c', 'ch', 'd', 'e', 'e',
        'e', 'f', 'g', 'h', 'i', 'j', 'ja', 'ju', 'k', 'l', 'm', 'n', 'o', 'p', 'r', 's', 'sh', 'shch', 't', 'u', 'v', 'y', 'z', 'zh', 'AE', 'OE', 'UE', 'ss', 'ae', 'oe', 'ue', 'C', 'G', 'I', 'S', 'c', 'g', 'i', 's',
        'A', 'E', 'G', 'I', 'K', 'L', 'N', 'U', 'a', 'e', 'g', 'i', 'k', 'l', 'n', 'u', 'G', 'I', 'Ji', 'Ye', 'g', 'i', 'ji', 'ye', 'C', 'D', 'E', 'N', 'R', 'S', 'T', 'U', 'Z', 'c', 'd', 'e', 'n', 'r', 's', 't', 'u', 'z',
        'A', 'C', 'E', 'L', 'N', 'O', 'S', 'Z', 'Z', 'a', 'c', 'e', 'l', 'n', 'o', 's', 'z', 'z',
    ];

    for (let key in from) {
        str = str.replace(new RegExp(from[key], 'g'), to[key])
    }

    return str;
}

function ajaxnav(params) {
    // ajaxnav(url, container, template, update_address, append_history)
    if (typeof params !== 'object') {
        params = {
            url: arguments[0],
            container: arguments[1],
            template: arguments[2],
            update_address: arguments[3] !== undefined ? arguments[3] : true,
            append_history: arguments[4] !== undefined ? arguments[4] : true,
        }
    }

    // uzpidom default'iniais parametrais
    params = $.extend({
        update_address: true,
        append_history: true,
        method: 'GET',
        data: {},
        error: function (data, textStatus, jqXHR) {
            $('#ajax_loader').hide();
            if (params.callback !== undefined) {
                params.callback(data, textStatus, jqXHR);
            }
        },
        success: function (data, textStatus, jqXHR) {
            $('#ajax_loader').hide();
            if (typeof data == 'object') {
                if (data.reload === true) {
                    $('#ajax_loader').show();
                    window.location.href = data.url;
                } else {
                    ajaxnav($.extend({
                        container: params.container,
                        template: params.template,
                        update_address: params.update_address,
                        append_history: params.append_history,
                        callback: params.callback
                    }, data));
                }
            } else if (typeof data == 'string') {
                if (params.container !== undefined) {
                    let $data = $(data);
                    if (!$data.attr('id')) {
                        $data.attr('id', 'id-' + Math.random().toString(36).substr(2, 16));
                    }
                    let $previous = $(params.container).replaceWith($data);
                    init_components($data);
                    if (params.update_address) {
                        var pso = {
                            template: params.template,
                            container: params.container
                        }
                        var final_url = jqXHR.getResponseHeader('X-AJAXNAV-URL');
                        if (!final_url) final_url = params.url;
                        if (params.append_history) {
                            // window.history.replaceState(pso, '', window.location);
                            window.history.pushState(pso, '', final_url);
                        } else {
                            window.history.replaceState(pso, '', final_url);
                        }
                    }
                }
                if (params.callback !== undefined) {
                    params.callback(data, textStatus, jqXHR);
                }
            }
        }
    }, params);

    // vykdom ajax request'a
    $.ajax({
        url: params.url + (!params.url.match(/\?/) ? '?' : (!params.url.match(/&$/) ? '&' : '')) + 'display=' + params.template,
        method: params.method,
        data: params.data,
        success: params.success,
        beforeSend: function () {
            $('#ajax_loader').show();
        },
    });
}

window.ajaxnav = ajaxnav;

function init_selectpicker(context) {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) && !$('.selectpicker', context).hasClass('non-mobile')) {
        $('.selectpicker', context).selectpicker('mobile');
    } else {
        $('.selectpicker', context).selectpicker();
    }
}

function init_components(context) {
    init_selectpicker(context);
}

window.init_components = init_components;

function init_ajaxnav() {
    // ajaxnav
    $(document).on('click', '[data-ajaxnav="true"]:not(form)', function (e) {
        var url = this.href !== undefined ? this.href : $(this).data('ajaxnav-url');
        var callback = $(this).data('ajaxnav-callback');
        if (url) {
            e.preventDefault();
            ajaxnav({
                url: url,
                container: $(this).data('ajaxnav-container'),
                template: $(this).data('ajaxnav-template'),
                callback: function () {
                    if (typeof window[callback] == 'function') {
                        window[callback]();
                    }
                }
            });
        }
    });

    $(document).on('submit', 'form[data-ajaxnav="true"]', function (e) {
        var url = this.action ? this.action : ($(this).data('ajaxnav-url') ? $(this).data('ajaxnav-url') : '');
        var callback = $(this).data('ajaxnav-callback');
        e.preventDefault();
        ajaxnav({
            url: url,
            method: this.method ? this.method.toUpperCase() : 'GET',
            data: $(this).serialize(),
            container: $(this).data('ajaxnav-container'),
            template: $(this).data('ajaxnav-template'),
            callback: function () {
                if (typeof window[callback] == 'function') {
                    window[callback]();
                }
            }
        });
        return false;
    });

    window.onpopstate = function (event) {
        if (event.state && event.state.container && event.state.template) {
            ajaxnav(window.location.href, event.state.container, event.state.template, false);
        } else {
            $('#ajax_loader').show();
            window.location.reload();
        }
    };

}

function update_wishlist_info() {
    $.ajax({
        url: '?display=content_types/wishlist/wishlist_info',
        method: 'post',
        success: function (html) {
            $('#wishlist-info').replaceWith(html);
        }
    });
}

function init_wishlist() {
    $(document).on('click', '.add-to-wishlist', function (e) {
        e.preventDefault();
        var el = $(this);
        if (el.data('id') == "") {
            alert($alert_message);
        } else {
            $.ajax({
                url: '?display=content_types/wishlist/update_wishlist',
                data: {element_id: el.data('id')},
                method: 'post',
                dataType: 'json',
                success: function (json) {
                    if (json.status > 0) {
                        el.addClass('active');
                    } else if (json.status < 0) {
                        el.removeClass('active');
                    }
                    update_wishlist_info();
                }
            });
        }
    });
}

function init_tooltip(el, text) {
    const tooltip = new bootstrap.Tooltip(el, {
        title: text,
        trigger: 'manual'
    });

    tooltip.show()

    setTimeout(function () {
        tooltip.hide()
    }, 2000);
}

function update_comparison_info() {
    $.ajax({
        url: '?display=content_types/comparison/comparison_info',
        method: 'post',
        success: function (html) {
            $('#comparison-info-wrp').replaceWith(html);
        }
    });
}

function init_comparison() {
    $(document).on('click', '.add-to-comparison', function (e) {
        e.preventDefault();
        let el = $(this);
        if (el.data('id') == "") {
            alert($alert_message);
        } else {
            $.ajax({
                url: '?display=content_types/comparison/update_comparison',
                data: {element_id: el.data('id')},
                method: 'post',
                dataType: 'json',
                success: function (json) {
                    if (json.status > 0) {
                        el.addClass('active');
                    } else if (json.status < 0) {
                        el.removeClass('active');
                    } else if (json.status === 0){
                        return init_tooltip(el, el.data('tooltip-notice'));
                    }

                    update_comparison_info();

                    if(el.hasClass('remove-comparison')){
                        ajaxnav('', '#comparison-listing', 'content_types/comparison/listing', false);
                    }
                }
            });
        }
    });
}

function init_page_editor_slider() {
    if (get_vw() < screen_sm) {
        $('.page_editor_columns_carousel').each(function () {
            if ($(this).find('.carousel-inner-x .item').length > 1) {
                var carousel_columns = tns({
                    container: $(this).find('.carousel-inner-x')[0],
                    items: 1,
                    autoplayButtonOutput: false,
                    autoplay: false,
                    loop: true,
                    nav: true,
                    controls: false,
                    autoplayTimeout: 5000,
                    autoHeight: true,
                    navPosition: 'bottom'
                });
            }
        });
    }
}

function init_categories_slider() {
    $('.categories_carousel').each(function() {
        if($(this).closest('#pages-detailed').length){
            var options = {
                container: $(this).find('.carousel-inner-x')[0],
                gutter: 20,
                autoplayButtonOutput: false,
                autoplay: false,
                loop: true,
                autoplayTimeout: 5000,
                navPosition: 'bottom',
                controlsContainer: $(this).find('.categories-controls')[0],
                responsive: {
                    0: {
                        items: 2,
                        nav: true,
                        controls: false,
                    },
                    576: {
                        items: 3,
                    },
                    768: {
                        items: 4,
                    },
                    992: {
                        items: 5,
                        nav: false,
                        controls: true,
                    },
                }
            };
        }else{
            var options = {
                container: $(this).find('.carousel-inner-x')[0],
                gutter: 20,
                autoplayButtonOutput: false,
                autoplay: false,
                loop: true,
                autoplayTimeout: 5000,
                navPosition: 'bottom',
                controlsContainer: $(this).find('.categories-controls')[0],
                responsive: {
                    0: {
                        items: 2,
                        nav: true,
                        controls: false,
                    },
                    576: {
                        items: 3,
                    },
                    768: {
                        items: 4,
                    },
                    992: {
                        items: 5,
                        nav: false,
                        controls: true,
                    },
                    1200: {
                        items: 6,
                    },
                }
            };
        }

        if ($(this).find('.carousel-inner-x .item').length > 1) {
            var carousel = tns(options);
        }
    });
}

function doModal(content, dialogClasses) {
    dialogClasses = typeof dialogClasses !== 'undefined' ? dialogClasses : 'modal-lg';
    let dialogCentered = get_vw() >= screen_sm ? 'modal-dialog-centered' : '';
    let html = '<div class="modal fade" id="dynamicModal" tabindex="-1" role="dialog">';
    html += '<div class="modal-dialog ' + dialogClasses + ' modal-fullscreen-sm-down modal-dialog-scrollable ' + dialogCentered + '" role="document">';
    html += '<div class="modal-content">';
    html += '<button class="close-btn" type="button" data-bs-dismiss="modal" aria-label="Close"><i class="fa-light fa-xmark"></i></button>';
    html += '<div class="modal-body">';
    html += content;
    html += '</div>'; 	// body
    html += '</div>';  	// content
    html += '</div>';  	// dialog
    html += '</div>';  	// modal
    $('body').append(html);
    $('#dynamicModal').modal();
    $('#dynamicModal').modal('show');
    $('#dynamicModal').on('hidden.bs.modal', function () {
        $(this).remove();
    });
}

function init_authorize() {
    $(document).on('click', '.need2login', function (e) {
        e.preventDefault();
        ajaxnav({
            url: window.location.href,
            template: 'content_types/customers/authorize',
            callback: function (html) {
                doModal(html, 'customers-auth');
            }
        });
    });
}

window.initCollectionSlider = function (container, standAlone) {
    standAlone = typeof standAlone !== 'undefined' ? standAlone : false;
    var singleCollSliderObj = {};
    let options = {
        autoplay: false,
        loop: false,
        gutter: 4,
        items: 2,
        autoplayButtonOutput: false,
        nav: true,
        navPosition: 'bottom',
        controls: false,
    };

    if (standAlone) {
        if (get_vw() < screen_sm && $(container).length && typeof singleCollSliderObj.isOn === 'undefined') {
            singleCollSliderObj = tns(Object.assign({}, options, {container: container}));
        } else if (get_vw() < screen_sm && $(container).length && singleCollSliderObj.isOn === null) {
            singleCollSliderObj = singleCollSliderObj.rebuild();
        } else if (get_vw() >= screen_sm && singleCollSliderObj.isOn) {
            singleCollSliderObj.destroy();
        }
        return;
    }

    return tns(Object.assign({}, options, {container: container}));
}

window.initCollectionsSlider = function () {
    let vw = get_vw();
    let collectionItems = $('.collection-items');

    if (vw < screen_sm && collectionItems.length && collectionsSliderobj.length === 0) {
        collectionItems.each(function () {
            collectionsSliderobj.push(initCollectionSlider(this));
        });
    } else if (vw < screen_sm && collectionItems.length && collectionsSliderobj[0].isOn === null) {
        $.each(collectionsSliderobj, function (key, obj) {
            collectionsSliderobj[key] = obj.rebuild();
        });
    } else if (vw >= screen_sm && collectionsSliderobj.length) {
        $.each(collectionsSliderobj, function (key, obj) {
            obj.destroy();
        });
    }
}

window.initProductPhotosSlider = function () {
    if($('.product-photos').length){
        var productPhotos = tns({
            container: '.product-photos',
            items: 1,
            autoplayButtonOutput: false,
            autoplay: false,
            mouseDrag: true,
            loop: false,
            navContainer: document.querySelector('.product-photos-nav'),
            controls: false,
            nav: true,
        });

        $('[data-fancybox="product"]').fancybox({
            beforeClose : function(instance) {
                productPhotos.goTo(instance.currIndex);
            }
        });
    }
}

window.initInstaPhotosSlider = function (container, standAlone) {
    standAlone = typeof standAlone !== 'undefined' ? standAlone : false;
    var singleInstaSliderObj = {};
    let options = {
        autoplay: false,
        loop: false,
        autoplayButtonOutput: false,
        nav: true,
        navPosition: 'bottom',
        controls: false,
        responsive: {
            0: {
                gutter: 32,
                items: 3,
            },
            1810: {
                gutter: 30,
                items: 4,
            },
        }
    };

    if (standAlone) {
        if (get_vw() > screen_md && $(container).length && typeof singleInstaSliderObj.isOn === 'undefined') {
            singleInstaSliderObj = tns(Object.assign({}, options, {container: container}));
        } else if (get_vw() > screen_md && $(container).length && singleInstaSliderObj.isOn === null) {
            singleInstaSliderObj = singleInstaSliderObj.rebuild();
        } else if (get_vw() < screen_md && singleInstaSliderObj.isOn) {
            singleInstaSliderObj.destroy();
        }
        return;
    }

    return tns(Object.assign({}, options, {container: container}));
}

window.initInstaPhotosSliders = function () {
    let vw = get_vw();
    let instaItems = $('.insta-list');

    if (vw >= screen_md && instaItems.length && singleInstaSliderObj.length === 0) {
        instaItems.each(function () {
            singleInstaSliderObj.push(initInstaPhotosSlider(this));
        });
    } else if (vw >= screen_md && instaItems.length && singleInstaSliderObj[0].isOn === null) {
        $.each(singleInstaSliderObj, function (key, obj) {
            singleInstaSliderObj[key] = obj.rebuild();
        });
    } else if (vw < screen_md && singleInstaSliderObj.length) {
        $.each(singleInstaSliderObj, function (key, obj) {
            obj.destroy();
        });
    }
}

function toggle_company(){
    if ( $('#company:checked').val() == 1) {
        $('.company-wrp').slideDown('fast');
        $('.company-wrp').find('input').removeAttr("disabled");
    }else{
        $('.company-wrp').find('input').attr("disabled", "disabled");
        $('.company-wrp').slideUp('fast');
    }
}

function showPartialVisible(){
    let showBtn = $('.js-show-partial-visible');
    if(!showBtn.length) return;
    let descBox = $('.partial-desc-box');
    let descBoxH = descBox.height();
    let partialEl = $(showBtn.data('partial'));
    let partialElH = partialEl.outerHeight();

    descBox.removeAttr('style');

    if(partialElH > descBoxH){
        showBtn.show();
    }else{
        showBtn.hide();
    }
}

function init_article_sliders_activity() {
    if (get_vw() > screen_sm_min && $('.editor_type_article_slider').length) {
        $('.editor_type_article_slider').each(function () {
            let parent_el = $(this);
            $(this).find('.item').on('mouseenter', function () {
                let img = $(this).data('img');
                let banner_column = parent_el.find('.banner_column');

                parent_el.find('.item').removeClass('active');
                banner_column.css('background-image', 'url("' + img + '")');
                $(this).addClass('active');
            });
        });
    }
}

function collection_header_content_height_calc(){
    let header = $('.listing-header.collection');
    if(!header.length) return;
    let read_more = $('.listing-header.collection .read-more');
    let content = $('.listing-header.collection .html-content');
    let contentBoxH = content.height();
    let content_items = $('.listing-header.collection .html-content > *');
    let content_items_height = 0;

    content_items.each(function () {
        content_items_height += parseFloat($(this).outerHeight(true));
    });

    if(content_items_height <= contentBoxH){
        content.addClass('more');
        read_more.addClass('d-none');
    }else{
        content.removeClass('more');
        read_more.removeClass('d-none');
    }
}

function init_quantity_control() {
    $(document).on('click', '.quantity-selector .plus, .quantity-selector .minus', function (e) {
        var $input = $(this).parents('.quantity-selector').find('input');
        var val = parseInt($input.val()) || 1;
        if ($(this).is('.minus')) {
            val--;
        } else {
            val++;
        }
        var min_val = $input.data('min') !== undefined ? $input.data('min') : 1;
        var max_val = $input.data('max') !== undefined ? $input.data('max') : 999;
        if (val < min_val) val = min_val;
        if (val > max_val) val = max_val;
        $input.val(val);
    });

    $(document).on('keyup', '.quantity-selector input', function () {
        $(this).closest('form').submit();
    });
}


function init_mega_menu(){
    $(document).on('click', '.js-toggle-mega-menu, #mega-menu-overlay', function (e) {
        e.preventDefault();
        $('.mega-menu-button').toggleClass('active');
        $('#mega-menu-mobile').toggleClass('active');
        $('.submenu-wrp').removeClass('active');
    });

    $('.js-toggle-dropdown').click(function (e) {
        e.preventDefault();
        $(this).closest('.sub').find('> .submenu-wrp').toggleClass('active');
    });

    $(document).on('click', '#mega-menu-desktop #mega-menu-overlay', function (e) {
        e.preventDefault();
        $('#site-header .main-menu a').removeClass('active');
        $('#mega-menu-desktop').removeClass('active');
    });

    $(document).on('mouseenter', '.main-bar-sm-menu a', function () {
        let target = $(this).data('target');
        let hasMenu = $(this).hasClass('has-menu');

        $('.main-bar-sm-menu .has-menu').removeClass('active');
        $('#mega-menu-desktop').removeClass('active');
        $('#mega-menu-desktop .submenu').removeClass('active');

        if(hasMenu){
            $(this).addClass('active');
            $('#mega-menu-desktop').addClass('active');
            $(target).addClass('active');
        }
    });

    $(document).on('mouseenter', '#mega-menu-overlay, #site-header-top', function () {
        $('.main-bar-sm-menu .has-menu').removeClass('active');
        $('#mega-menu-desktop').removeClass('active');
        $('#mega-menu-desktop .submenu').removeClass('active');
    });

    // $(document).on('mouseleave', '.main-bar-sm-menu a', function (e) {
    //     console.log($(e.target));
    //     if (!$(e.target).closest('#quick-search').length && !$(e.target).is('#quick-search') && get_vw() > screen_md) {
    //
    //     }
    //     $(this).removeClass('active');
    //     $('#mega-menu-desktop').removeClass('active');
    //     $('#mega-menu-desktop .submenu').removeClass('active');
    // });
}

$(function () {
    init_components(document);
    init_ajaxnav();
    init_authorize();
    init_wishlist();
    init_comparison();
    initCollectionsSlider();
    initInstaPhotosSliders();
    initProductPhotosSlider();
    showPartialVisible();
    init_page_editor_slider();
    init_categories_slider();
    init_article_sliders_activity()
    collection_header_content_height_calc();
    init_quantity_control();
    init_mega_menu();

    $(window).on('resize', function () {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
            initCollectionsSlider();
            showPartialVisible();
            initInstaPhotosSliders();
        }, 250);
    });

    //new/updated

    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));

    if (typeof $pages_news_count !== 'undefined') {
        var $page_news = 2;
        const newsBtnWrp = $('#news-list-page .btn-wrp');

        if (parseInt($page_news) > parseInt($pages_news_count)) {
            newsBtnWrp.hide();
        }

        $('#news-list-page .btn-wrp').click(function (e) {
            e.preventDefault();
            $('#ajax_loader').show();
            newsBtnWrp.hide();

            $.get('?display='+ $(this).data('template') +'&page=' + $page_news, function (data) {
                $page_news++;
                $('#news-list-page .news-list').append($(data).find('.news-list').html());
                $('#ajax_loader').hide();

                if (parseInt($page_news) <= parseInt($pages_news_count)) {
                    newsBtnWrp.show();
                }
            });
        });
    }

    if($('#linepromos').length){
        tns({
            container: '.linepromos-carousel',
            items: 1,
            autoplayButtonOutput: false,
            autoplay: true,
            loop: true,
            nav: false,
            controls: false,
            autoplayTimeout: 5000,
            speed: 1000,
            controlsText: [
                '<i class="fa-light fa-chevron-left"></i>',
                '<i class="fa-light fa-chevron-right"></i>'
            ],
        });
    }

    $(document).on('click', '.js-toggle-search', function (e) {
        e.preventDefault();
        let quickSearch = $('#quick-search');

        quickSearch.toggleClass('active');

        if (quickSearch.hasClass('active')) {
            quickSearch.find('input[name="search"]').val('');

            quickSearch.find('#search-suggestions-results').empty();

            setTimeout(function () {
                quickSearch.find('input[name="search"]').focus();
            }, 100);
        }
    });

    var search_delay = null;
    $("#quick-search-form input[name='search']").keyup(function () {
        let val = $(this).val();
        if (val.length > 2) {
            if (search_delay != null) clearTimeout(search_delay);
            search_delay = setTimeout(function () {
                $("#search-suggestions-results").html(loader);
                $('#quick-search').addClass('active');

                let search_request = $.ajax({
                    type: 'POST',
                    url: '?display=content_types/search/suggestions.tpl',
                    data: {q: val},
                    success: function (data) {
                        $("#search-suggestions-results").html(data);
                    }
                });
            }, 1000);
        } else {
            if (val.length > 0) {
                $("#search-suggestions-results").html(moreChars);
                $('#quick-search').addClass('active');
            } else {
                $('#quick-search').removeClass('active');
            }
        }
    });

    $('html body').click(function (e) {
        if (!$(e.target).closest('#quick-search').length && !$(e.target).is('#quick-search') && get_vw() > screen_md) {
            $('#quick-search').removeClass('active');
        }
    });

    if ($('#hero-slider-desktop').length) {
        var heroSliderDesktop = tns({
            container: '#hero-slider-desktop',
            items: 1,
            autoplayButtonOutput: false,
            autoplay: false,
            mouseDrag: true,
            nav: false,
            controls: false,
            autoplayTimeout: 10000,
            speed: 500,
            onInit: function (info) {
                $('.hero-slider-desktop .hero-nav-counter .total').text(info.slideCount);
            }
        });

        heroSliderDesktop.events.on('indexChanged', function (info) {
            $('.hero-slider-desktop .hero-nav .current').text(info.displayIndex);
        });

        $('.hero-slider-desktop .hero-arrow-btn.prev').click(function (e) {
            e.preventDefault();
            heroSliderDesktop.pause();
            heroSliderDesktop.goTo('prev');
            heroSliderDesktop.play();
        });

        $('.hero-slider-desktop .hero-arrow-btn.next').click(function (e) {
            e.preventDefault();
            heroSliderDesktop.pause();
            heroSliderDesktop.goTo('next');
            heroSliderDesktop.play();
        });
    }

    if ($('#hero-slider-mobile').length) {
        var heroSliderMobile = tns({
            container: '#hero-slider-mobile',
            items: 1,
            autoplayButtonOutput: false,
            autoplay: false,
            mouseDrag: true,
            nav: false,
            controls: false,
            autoplayTimeout: 10000,
            speed: 500,
            onInit: function (info) {
                $('.hero-slider-mobile .hero-nav-counter .total').text(info.slideCount);
            }
        });

        heroSliderMobile.events.on('indexChanged', function (info) {
            $('.hero-slider-mobile .hero-nav .current').text(info.displayIndex);
        });

        $('.hero-slider-mobile .hero-arrow-btn.prev').click(function (e) {
            e.preventDefault();
            heroSliderMobile.pause();
            heroSliderMobile.goTo('prev');
            heroSliderMobile.play();
        });

        $('.hero-slider-mobile .hero-arrow-btn.next').click(function (e) {
            e.preventDefault();
            heroSliderMobile.pause();
            heroSliderMobile.goTo('next');
            heroSliderMobile.play();
        });
    }

    $(document).on('click', '#payment-type-options .show-less-more', function (e) {
        e.preventDefault();
        $('#payment-type-options').toggleClass('show-all');
    });

    if($('#side-nav-sticky').length){
        var sidebar = document.getElementById('side-nav-sticky');
        new StickySidebar(sidebar, {
            containerSelector: '#pages-detailed .content-wrp',
            innerWrapperSelector: '#side-nav-sticky ul',
            topSpacing: $('#site-header').outerHeight(true) + 30,
            bottomSpacing: 0
        });

        $(document).on('click', '#side-nav-sticky a[href^="#"]', function (e) {
            e.preventDefault();

            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top - $('#site-header').outerHeight(true) - 30
            }, 0);
        });

        $(window).bind('scroll', function () {
            var currentTop = $(window).scrollTop();
            var elems = $('.page_editor_template');
            var headerH = $('#site-header').outerHeight(true) + 30;
            elems.each(function (index) {
                var elemTop = $(this).offset().top - headerH;
                var elemBottom = elemTop + $(this).height();
                if (currentTop >= elemTop && currentTop <= elemBottom) {
                    var id = $(this).attr('id');
                    var navElem = $('a[href="#' + id + '"]');
                    navElem.addClass('active').parent().siblings().find('a').removeClass('active');
                }
            })
        });
    }

    $(document).on('click', '.show-psw', function (e) {
        e.preventDefault();

        let parent = $(this).closest('.show-psw-wrp');
        let input = parent.find('input');

        if (input.attr('type') === 'password') {
            $(this).addClass('show')
            input.attr('type', 'text');
        } else {
            $(this).removeClass('show')
            input.attr('type', 'password');
        }
    });

    $(document).on('click', '.listing-header .read-more', function (e) {
        e.preventDefault();

        $(this).toggleClass('less');
        $(this).closest('.listing-header').find('.html-content').toggleClass('more');
    });

    $('.js-toggle-dropdown').click(function (e) {
        e.preventDefault();

        $(this).closest('.dropdown').find('> .submenu').toggleClass('active');
    });

    $(document).on('click', '.js-show-partial-visible', function (e) {
        e.preventDefault();
        let partialEl = $($(this).data('partial'));
        let partialElH = partialEl.outerHeight();
        let moreText = $(this).data('more-text');
        let lessText = $(this).data('less-text');

        if($(this).hasClass('more')){
            $(this).removeClass('more');
            $(this).text(moreText);
            partialEl.parent().removeAttr('style');
        }else{
            $(this).addClass('more');
            $(this).text(lessText);
            partialEl.parent().css('max-height', partialElH + 'px');
        }
    });

    $(document).on('click', '.js-show-specs', function (e) {
        e.preventDefault();
        let moreText = $(this).data('more-text');
        let lessText = $(this).data('less-text');

        if($(this).hasClass('more')){
            $(this).removeClass('more');
            $(this).text(moreText);
            $('.specs table').removeClass('show');
        }else{
            $(this).addClass('more');
            $(this).text(lessText);
            $('.specs table').addClass('show');
        }
    });

    $(document).on('click', '.filter-limit-btn', function (e) {
        e.preventDefault();
        let parent = $(this).closest('.filter-group');
        let list = parent.find('ul');

        if($(this).hasClass('more')){
            $(this).addClass('d-none');
            parent.find('.filter-limit-btn.less').removeClass('d-none');
            list.removeClass('limit-list-display');
        }else{
            $(this).addClass('d-none');
            parent.find('.filter-limit-btn.more').removeClass('d-none');
            list.addClass('limit-list-display');
        }
    });

    $(document).on('change', '#cart-items select', function() {
        $(this).closest('form').submit();
    });

    $("#company").click(toggle_company);
    toggle_company();

    $(document).on('click', '.warranty', function(e) {
        e.preventDefault();
        ajaxnav({
            url: window.location.href,
            template: 'content_types/products/warranty',
            callback: function (html) {
                doModal(html, 'html-content');
            }
        });
    });

    $(document).on('click', '#payment-type-options .payment-item', function() {
        $('#payment-type-options input[name="payment_type"]').prop('checked', false);
    });

    $(document).on('click', '#payment-type-options [data-payment-name]', function() {
        $("#orders-pay button[type='submit']").attr('data-dl-payment', $(this).data('payment-name'));
    });

    $(document).on('change', '.coupon-wrp input[name="coupon_no_greetings"]', function () {
        let block = $('.coupon-wrp .greetings-form');

        if ($(this).is(':checked')) {
            block.hide();
            block.find('input, textarea').attr('disabled', true);
        } else {
            block.show();
            block.find('input, textarea').removeAttr('disabled');
        }
    });

    $(document).on('mouseenter', '.product-element', function () {
        $(this).css('min-height', $(this).outerHeight(true) + 'px');
        $(this).addClass('hover');
    });

    $(document).on('mouseleave', '.product-element', function () {
        $(this).css('min-height', 'auto');
        $(this).removeClass('hover');
    });

    $(document).on('click', '.add-to-cart-listing', function(e){
        e.preventDefault();
        var el = $(this);
        let data = { state: 'add2cart', item_id: el.data('id'), amount: 1 };

        ajaxnav({
            url: el.data('url'),
            data: data,
            method: 'POST',
            template: 'content_types/products/add2cart',
            callback: function() {
                ajaxnav('', '#cart-info-wrp', 'content_types/cart/cart_info&item_id='+ el.data('id') +'&add2cart=1', false);
            }
        });
    });

    $(document).on('click', "[data-dl-info]:not(.add_to_wishlist)", function(e) {
        var info = $(this).data('dl-info');
        var event = $(this).data('dl-event');
        var crm_id = $(this).data('dl-crm-id');

        if (event == 'begin_checkout') {
            dl_begin_checkout(info, crm_id);
        } else if (event == 'add_shipping_info') {
            let shipping_method = $(this).data('dl_shipping');

            if (shipping_method) {
                info.shipping_tier = shipping_method;
                dl_add_shipping_info(info, crm_id);
            }
        } else if (event == 'add_payment_info') {
            let payment_method = $(this).data('dl-payment');

            if (payment_method) {
                info.payment_type = payment_method;
                dl_add_payment_info(info, crm_id);
            }
        } else {
            dl_select_item(info, crm_id);
        }
    });

    $(document).on('click', '.insta-item', function(e) {
        e.preventDefault();
        let position = $(this).data('position');

        $.ajax({
            url: '?display=content_types/pages/insta_photos_modal',
            data: {position: position},
            method: 'POST',
            success: function (html) {
                doModal(html, 'insta-modal');
            }
        });
    });

    $(document).on('click', '.insta-modal-nav', function(e) {
        e.preventDefault();
        let position = $(this).data('position');

        $.ajax({
            url: '?display=content_types/pages/insta_photos_modal',
            data: {position: position},
            method: 'POST',
            success: function (html) {
                $('.insta-modal .modal-body').html(html);
            }
        });
    });

    if (get_vw() < screen_md) {
        $(document).on('click', '.product-dot', function(e) {
            e.preventDefault();
            window.location.href = $(this).data('link');
        });
    }else{
        const popoverTriggerList = document.querySelectorAll('.product-dot');
        const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl, {
            'trigger': 'click',
            'placement': 'top',
            'customClass': 'product-dot-content',
            'html': true,
            'offset': '[12, 12]',
            'content': $('#' + $(popoverTriggerEl).data('dot')).html(),
        }));

        $(document).on('click', '.product-dot', function(e) {
            $('.product-dot').not(this).popover('hide');
        });
    }
});

// datalayer select_item
function dl_select_item(productObj, crm_id) {
    let obj = {
        event: "select_item",
        ecommerce: {
            items: [                                           // required
                productObj
            ]
        }
    };
    if (typeof crm_id != 'undefined') {
        obj['user_id'] = crm_id;
        obj['crm_id'] = crm_id;
    }

    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    dataLayer.push(obj);
}

function dl_begin_checkout(ecommerceObj, crm_id) {
    let obj = {
        event: "begin_checkout",
        ecommerce: ecommerceObj
    };
    if (typeof crm_id != 'undefined') {
        obj['user_id'] = crm_id;
        obj['crm_id'] = crm_id;
    }

    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    dataLayer.push(obj);
}

function dl_add_shipping_info(ecommerceObj, crm_id) {
    let obj = {
        event: "add_shipping_info",
        ecommerce: ecommerceObj
    };
    if (typeof crm_id != 'undefined') {
        obj['user_id'] = crm_id;
        obj['crm_id'] = crm_id;
    }

    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    dataLayer.push(obj);

}

function dl_add_payment_info(ecommerceObj, crm_id) {
    let obj = {
        event: "add_payment_info",
        ecommerce: ecommerceObj
    };
    if (typeof crm_id != 'undefined') {
        obj['user_id'] = crm_id;
        obj['crm_id'] = crm_id;
    }

    dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    dataLayer.push(obj);
}